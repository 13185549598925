import {
  HubtypeBot,
  HubtypeBotConfig,
  HubtypeFlowVersion,
  HubtypeKnowledgeSource,
  HubtypeFeatureFlags,
  HubtypeProject,
  HubtypeUser,
} from '@hubtype/data-access-models'
import {
  Edge,
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
  ReactFlowInstance,
} from 'reactflow'

import {
  BotActionFields,
  CarouselFields,
  ChannelFields,
  ContentType,
  CountryConditionFields,
  FallbackFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  InputContentType,
  KeywordFields,
  MessageContentType,
  PayloadFields,
  QueueStatusFields,
  StartContentType,
  StartFields,
  TextFields,
  UrlFields,
  VideoFields,
  WhatsappButtonListFields,
} from '../../domain/models/content-fields'
import { ConditionalContentType } from '../../domain/models/content-fields'
import { LocaleCode } from '../../domain/models/locales/code'
import { CountryCode } from '../../domain/models/locales/country-code'
import { Locale } from '../../domain/models/locales/locale'
import { CustomConditionNodeInterface } from '../../nodes/custom-conditional'
import { KnowledgeBaseNodeInterface } from '../../nodes/knowledge-base'
import { SmartIntentNodeInterface } from '../../nodes/smart-intent/node'
import { WhatsappCTAUrlButtonNodeInterface } from '../../nodes/whatsapp-cta-url-button'
import { ModalContent } from '../components/modal/modal-types'
import { ChangesHistory } from '../reducer/action-types'

export type IdMapping = Record<string, string>

export interface TextNode extends ReactFlowNode<TextFields> {
  data: TextFields
  type: MessageContentType.TEXT
}

export interface TextNodeProps extends ReactFlowNodeProps<TextFields> {
  data: TextFields
  type: MessageContentType.TEXT
}

export interface CarouselNode extends ReactFlowNode<CarouselFields> {
  data: CarouselFields
  type: MessageContentType.CAROUSEL
}

export interface CarouselNodeProps extends ReactFlowNodeProps<CarouselFields> {
  data: CarouselFields
  type: MessageContentType.CAROUSEL
}

export interface ImageNode extends ReactFlowNode<ImageFields> {
  data: ImageFields
  type: MessageContentType.IMAGE
}

export interface ImageNodeProps extends ReactFlowNodeProps<ImageFields> {
  data: ImageFields
  type: MessageContentType.IMAGE
}

export interface VideoNode extends ReactFlowNode<VideoFields> {
  data: VideoFields
  type: MessageContentType.VIDEO
}

export interface VideoNodeProps extends ReactFlowNodeProps<VideoFields> {
  data: VideoFields
  type: MessageContentType.VIDEO
}

export interface HandoffNode extends ReactFlowNode<HandoffFields> {
  data: HandoffFields
  type: MessageContentType.HANDOFF
}

export interface HandoffNodeProps extends ReactFlowNodeProps<HandoffFields> {
  data: HandoffFields
  type: MessageContentType.HANDOFF
}

export interface KeywordNode extends ReactFlowNode<KeywordFields> {
  data: KeywordFields
  type: InputContentType.KEYWORD
}

export interface KeywordNodeProps extends ReactFlowNodeProps<KeywordFields> {
  data: KeywordFields
  type: InputContentType.KEYWORD
}

export interface QueueStatusNode extends ReactFlowNode<QueueStatusFields> {
  data: QueueStatusFields
  type: ConditionalContentType.QUEUE_STATUS
}

export interface QueueStatusNodeProps
  extends ReactFlowNodeProps<QueueStatusFields> {
  data: QueueStatusFields
  type: ConditionalContentType.QUEUE_STATUS
}

export interface ChannelNode extends ReactFlowNode<ChannelFields> {
  data: ChannelFields
  type: ConditionalContentType.CHANNEL
}

export interface ChannelNodeProps extends ReactFlowNodeProps<ChannelFields> {
  data: ChannelFields
  type: ConditionalContentType.CHANNEL
}

export interface FallbackNode extends ReactFlowNode<FallbackFields> {
  data: FallbackFields
  type: ConditionalContentType.FALLBACK
}

export interface FallbackNodeProps extends ReactFlowNodeProps<FallbackFields> {
  data: FallbackFields
  type: ConditionalContentType.FALLBACK
}

export interface WhatsappButtonListNode
  extends ReactFlowNode<WhatsappButtonListFields> {
  data: WhatsappButtonListFields
  type: MessageContentType.WHATSAPP_BUTTON_LIST
}

export interface WhatsappButtonListNodeProps
  extends ReactFlowNodeProps<WhatsappButtonListFields> {
  data: WhatsappButtonListFields
  type: MessageContentType.WHATSAPP_BUTTON_LIST
}

export interface GoToFlowNode extends ReactFlowNode<GoToFlowFields> {
  data: GoToFlowFields
  type: MessageContentType.GO_TO_FLOW
}

export interface GoToFlowNodeProps extends ReactFlowNodeProps<GoToFlowFields> {
  data: GoToFlowFields
  type: MessageContentType.GO_TO_FLOW
}

export interface BotActionNode extends ReactFlowNode<BotActionFields> {
  data: BotActionFields
  type: MessageContentType.BOT_ACTION
}

export interface BotActionNodeProps
  extends ReactFlowNodeProps<BotActionFields> {
  data: BotActionFields
  type: MessageContentType.BOT_ACTION
}

export interface StartNode extends ReactFlowNode<StartFields> {
  data: StartFields
  type: StartContentType.START
}

export interface StartNodeProps extends ReactFlowNodeProps<StartFields> {
  data: StartFields
  type: StartContentType.START
}

export interface CountryConditionNode
  extends ReactFlowNode<CountryConditionFields> {
  data: CountryConditionFields
  type: ConditionalContentType.COUNTRY_CONDITION
}

export interface CountryConditionNodeProps
  extends ReactFlowNodeProps<CountryConditionFields> {
  data: CountryConditionFields
  type: ConditionalContentType.COUNTRY_CONDITION
}

export type NodeTypes =
  | TextNode
  | CarouselNode
  | ImageNode
  | HandoffNode
  | KeywordNode
  | QueueStatusNode
  | ChannelNode
  | FallbackNode
  | VideoNode
  | WhatsappButtonListNode
  | GoToFlowNode
  | BotActionNode
  | StartNode
  | CountryConditionNode
  | CustomConditionNodeInterface
  | SmartIntentNodeInterface
  | WhatsappCTAUrlButtonNodeInterface
  | KnowledgeBaseNodeInterface

export type FlowElement = NodeTypes | Edge

export interface State {
  currentLocale: Locale
  currentNode?: NodeTypes
  nodes: NodeTypes[]
  isLocalesPanelOpen: boolean
  locales: Locale[]
  nonMessageContents: NonMessageContents
  organizationContents: OrganizationContents
  modalContent?: ModalContent
  reactFlowInstance?: ReactFlowInstance
  reactFlowWrapper?: React.RefObject<any>
  authToken: string
  isReadOnly: boolean
  botVariables: string[]
  isKnowledgeBaseActive: boolean
  isFlowSaved: boolean
  changeHistory: ChangesHistory[]
  historyIndex: number
  flows: Flow[]
  computedPreviousFlows: ComputedPreviousFlow[]
  currentFlowId: string
  webviews: Webview[]
  currentWebviewId?: string
  hashPublished: string
  hash: string
  botConfig?: HubtypeBotConfig
  loadingMessage?: LoadingMessage
  currentVersion?: HubtypeFlowVersion
  versionHistoryData: HubtypeFlowVersion[]
  isOffline: boolean
  hubtypeUser?: HubtypeUser
  interactionMode: InteractionMode
}

export enum InteractionMode {
  View = 'view',
  Edit = 'edit',
}

export interface Flow {
  id: string
  name: string
}

export interface ComputedPreviousFlow {
  id: string
  previousFlows: Flow[]
  showPreviousFlows: boolean
}
export interface Webview {
  id: string
  name: string
}

export interface NonMessageContents {
  payloads: PayloadFields[]
  urls: UrlFields[]
}

export interface OrganizationContents {
  projects: HubtypeProject[]
  featureFlags: HubtypeFeatureFlags
  knowledgeSources: HubtypeKnowledgeSource[]
  currentBot: HubtypeBot
  bots: HubtypeBot[]
  knowledgeBaseDefaultInstructions: string
}

export interface FlowContent {
  nodes: NodeTypes[]
  isPublished?: boolean
  nonMessageContents: NonMessageContents
  isKnowledgeBaseActive: boolean
  locales: string[]
  botVariables: string[]
  flows: Flow[]
  hash: string
}

export interface Credentials {
  accessToken: string
  environment: string
  manageToken: string
  spaceId: string
}

export enum EdgeType {
  Bezier = 'Bezier',
}

export interface TreeEntryContent {
  id: string
  nodeId: string
  code: string
  contentType: ContentType
  children?: TreeEntryContent[]
  isLink?: boolean
}

export enum LoadingMessage {
  AddLocales = 'Adding locales',
  LoadingApp = 'Loading Flow Builder',
  LoadingContent = 'Loading content',
  LoadingVersion = 'Loading version',
  Publish = 'Publishing',
  RemoveLocales = 'Removing locales',
  Save = 'Saving',
  RestoringVersion = 'Restoring version',
  TransferFlows = 'Transferring flows',
}

export enum PreviewUrlParams {
  BotId = 'bot_id',
  IsShared = 'shared',
  WebchatOpenAuto = 'open',
  WebchatAppId = 'app_id',
  WebchatRefreshCount = 'refresh_count',
  BundleLocation = 'bundle_location',
  CurrentLocale = 'current',
  CurrentCountry = 'currentCountry',
  Locales = 'locales',
}

export interface FlowBuilderPreviewState {
  hasFlowErrors: boolean
  current: LocaleCode
  locales: LocaleCode[]
  currentCountry?: CountryCode
}

export interface WebchatDimensions {
  ready: boolean
  width: number
  height: number
}
