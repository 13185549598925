import { HubtypeFlowVersion, HubtypeUser } from '@hubtype/data-access-models'
import { useToastFeedback } from '@hubtype/ui-react-web'

import { FeedbackType } from '../../../../domain/feedback'
import { FlowBuilderService } from '../../../../repository/hubtype/flow-builder-service'
import { HubtypeService } from '../../../../repository/hubtype/hubtype-service'
import { useRepository } from '../../../../repository/repository-utils'
import { SaveOrigin } from '../../../analytics'
import { GENERIC_ERROR_MESSAGE_DESCRIPTION } from '../../../constants'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { LoadingMessage } from '../../../types'
import { useSave } from '../save/use-save'

export const usePublish = () => {
  const repository = useRepository()
  const feedback = useToastFeedback()
  const { saveFlow } = useSave()
  const {
    state,
    setLoadingMessage,
    setHashPublished,
    setVersionHistoryData,
    selectNode,
  } = useFlowBuilderSelector(ctx => ctx)

  const publishFlow = async () => {
    setLoadingMessage(LoadingMessage.Publish)
    const savedFlowHash = await saveFlow(SaveOrigin.BEFORE_PUBLISH)
    const hasFlowErrors = state.nodes.some(
      node =>
        node.data.errors.fieldErrors.length ||
        node.data.errors.hasDuplicatedIdentifier
    )
    if (hasFlowErrors) {
      // This will display the errors of the current node
      state.currentNode && selectNode(state.currentNode)
      feedback.addError(
        'Failed to publish flow',
        'Review alerts and try again.'
      )
    }
    if (savedFlowHash && !hasFlowErrors) {
      const publishedStatus = await repository.cmsEnvironment.publishFlow(
        state.authToken,
        state.organizationContents.currentBot.id
      )
      if (publishedStatus === FeedbackType.SUCCESS) {
        setHashPublished(savedFlowHash)
        await getVersionHistoryData(state.organizationContents.currentBot.id)
      }
      reportFlowPublished(publishedStatus)
    }
    setLoadingMessage(undefined)
  }

  const reportFlowPublished = (publishedStatus: FeedbackType): void => {
    if (publishedStatus === FeedbackType.SUCCESS) {
      feedback.addSuccess('Published')
    } else if (publishedStatus === FeedbackType.ERROR) {
      feedback.addError(
        'Failed to publish flow',
        GENERIC_ERROR_MESSAGE_DESCRIPTION
      )
    }
  }

  const getVersionHistoryData = async (botId: string): Promise<void> => {
    const versionData = await FlowBuilderService.getVersionHistory(
      state.authToken,
      botId
    )
    if (!Array.isArray(versionData)) return
    const dataWithUserInfo = await populateVersionsUserInfo(
      versionData,
      state.authToken
    )
    setVersionHistoryData(dataWithUserInfo)
  }

  return { publishFlow, getVersionHistoryData }
}

const populateVersionsUserInfo = async (
  versionData: HubtypeFlowVersion[],
  authToken: string
): Promise<HubtypeFlowVersion[]> => {
  const uniqueUserIds = [...new Set(versionData.map(user => user.createdBy))]
  const userInfo: Record<string, HubtypeUser | undefined> = {}
  const promises = uniqueUserIds.map(id =>
    HubtypeService.getUserData(authToken, id)
  )
  const usersData = await Promise.all(promises)
  usersData.forEach(data => {
    if (data) userInfo[data.id] = data
  })
  return versionData.map(data => {
    const user = userInfo[data.createdBy]
    if (user) {
      data.setUser(user)
    }
    return data
  })
}
