import {
  HubtypeBotConfig,
  HubtypeFlowVersion,
  HubtypeUser,
} from '@hubtype/data-access-models'
import { ReactFlowInstance } from 'reactflow'

import { ModalContent } from '../components/modal/modal-types'
import {
  ComputedPreviousFlow,
  InteractionMode,
  LoadingMessage,
  NodeTypes,
  NonMessageContents,
  OrganizationContents,
} from '../types'
import { AiActionInterface, AiChangesHistory } from './actions/ai-actions'
import { EdgeActionInterface, EdgeChangesHistory } from './actions/edge-actions'
import { FlowActionInterface, FlowChangesHistory } from './actions/flow-actions'
import {
  LocaleActionInterface,
  LocaleChangesHistory,
} from './actions/locale-actions'
import { NodeActionInterface, NodeChangesHistory } from './actions/node-actions'
import {
  NonMessageActionInterface,
  NonMessageChangesHistory,
} from './actions/non-message-actions'
import {
  WebviewActionInterface,
  WebviewChangesHistory,
} from './actions/webview-actions'

export type ChangesHistory =
  | AiChangesHistory
  | EdgeChangesHistory
  | FlowChangesHistory
  | LocaleChangesHistory
  | NodeChangesHistory
  | NonMessageChangesHistory
  | WebviewChangesHistory

export type Action =
  | AiActionInterface
  | EdgeActionInterface
  | FlowActionInterface
  | LocaleActionInterface
  | NodeActionInterface
  | NonMessageActionInterface
  | WebviewActionInterface
  | { type: ActionType.ToggleFlowSaved; isFlowSaved: boolean }
  | { type: ActionType.SetAuthToken; authToken: string }
  | { type: ActionType.ToggleLocalesPanel; toggle: boolean }
  | { type: ActionType.CloseNodeEditor }
  | { type: ActionType.ToggleInteractivity; isInteractive: boolean }
  | { type: ActionType.SetModalContent; content?: ModalContent }
  | {
      type: ActionType.SetReactFlowRefs
      reactFlowInstance: ReactFlowInstance
      reactFlowWrapper: React.RefObject<HTMLDivElement>
    }
  | {
      type: ActionType.SetComputedPreviousFlows
      computedPreviousFlows: ComputedPreviousFlow[]
    }
  | {
      type: ActionType.SetOrganizationContents
      organizationContents: OrganizationContents
    }
  | {
      type: ActionType.UpdateAllContents
      nodes?: NodeTypes[]
      nonMessageContents?: NonMessageContents
    }
  | { type: ActionType.Undo }
  | { type: ActionType.Redo }
  | { type: ActionType.RestoreChangeHistory }
  | { type: ActionType.SetHashPublished; hashPublished: string }
  | { type: ActionType.SetHash; hash: string }
  | { type: ActionType.SetBotConfig; botConfig: HubtypeBotConfig }
  | { type: ActionType.SetLoadingMessage; message?: LoadingMessage }
  | {
      type: ActionType.SetCurrentVersion
      currentVersion?: HubtypeFlowVersion
    }
  | { type: ActionType.ToggleIsOffline; isOffline: boolean }
  | {
      type: ActionType.SetVersionHistoryData
      versionHistoryData: HubtypeFlowVersion[]
    }
  | { type: ActionType.SetHubtypeUser; user: HubtypeUser }
  | { type: ActionType.SetInteractionMode; mode: InteractionMode }

export enum ActionType {
  AddFlow = 'ADD_FLOW',
  AddLocales = 'ADD_LOCALES',
  AddPayload = 'ADD_PAYLOAD',
  AddUrl = 'ADD_URL',
  AddWebview = 'ADD_WEBVIEW',
  CloseNodeEditor = 'CLOSE_NODE_EDITOR',
  ConnectNodes = 'CONNECT_NODES',
  CopyElements = 'COPY_ELEMENTS',
  CutElements = 'CUT_ELEMENTS',
  EditUrl = 'EDIT_URL',
  NodeDragStart = 'NODE_DRAG_START',
  NodeDragStop = 'NODE_DRAG_STOP',
  NodeDrop = 'NODE_DROP',
  PasteElements = 'PASTE_ELEMENTS',
  Redo = 'REDO',
  RemoveEdgesById = 'REMOVE_EDGES_BY_ID',
  RemoveFlow = 'REMOVE_FLOW',
  RemoveLocales = 'REMOVE_LOCALES',
  RemoveNodes = 'REMOVE_NODES',
  RemovePayload = 'REMOVE_PAYLOAD',
  RemoveUrl = 'REMOVE_URL',
  RemoveWebview = 'REMOVE_WEBVIEW',
  RestoreChangeHistory = 'RESTORE_CHANGE_HISTORY',
  SelectEdges = 'SELECT_EDGES',
  SelectFlow = 'SELECT_FLOW',
  SelectLocale = 'SELECT_LOCALE',
  SelectNode = 'SELECT_NODE',
  SelectWebview = 'SELECT_WEBVIEW',
  SetAuthToken = 'SET_AUTH_TOKEN',
  SetBotConfig = 'SET_BOT_CONFIG',
  SetBotVariables = 'SET_BOT_VARIABLES',
  SetComputedPreviousFlows = 'SET_COMPUTED_PREVIOUS_FLOWS',
  SetCurrentVersion = 'SET_CURRENT_VERSION',
  SetHubtypeUser = 'SET_HUBTYPE_USER',
  SetFlows = 'SET_FLOWS',
  SetHash = 'SET_HASH',
  SetHashPublished = 'SET_HASH_PUBLISHED',
  SetInteractionMode = 'SET_INTERACTION_MODE',
  SetKnowledgeBaseActive = 'SET_KNOWLEDGE_BASE_ACTIVE',
  SetLoadingMessage = 'SET_LOADING_MESSAGE',
  SetLocales = 'SET_LOCALES',
  SetModalContent = 'SET_MODAL_CONTENT',
  SetOrganizationContents = 'SET_ORGANIZATION_CONTENTS',
  SetReactFlowRefs = 'SET_REACT_FLOW_REFS',
  SetSelectedNodes = 'SET_SELECTED_NODES',
  SetVersionHistoryData = 'SET_VERSION_HISTORY_DATA',
  SetWebviews = 'SET_WEBVIEWS',
  ToggleFlowSaved = 'TOGGLE_FLOW_SAVED',
  ToggleInteractivity = 'TOGGLE_INTERACTIVITY',
  ToggleIsOffline = 'TOGGLE_IS_OFFLINE',
  ToggleLocalesPanel = 'TOGGLE_LOCALES_PANEL',
  Undo = 'UNDO',
  UpdateAllContents = 'UPDATE_ALL_CONTENTS',
  UpdateAllNodes = 'UPDATE_ALL_NODES',
  UpdateNode = 'UPDATE_NODE',
}
