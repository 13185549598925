import {
  HubtypeFlow,
  HubtypeFlowBuilderData,
} from '@hubtype/data-access-models'

import { CMSWriter, CMSWriteResponse } from '../../domain/models/cms'
import { AssetFields } from '../../domain/models/content-fields'
import { DRAFT_VERSION, MAIN_FLOW } from '../../UI/constants'
import { State } from '../../UI/types'
import { isWebview } from '../../webviews/utils'
import { getHash } from '../hash-utils'
import { FlowBuilderService } from '../hubtype/flow-builder-service'
import { HtFlowFactory } from './ht-flow-factory'
import { NodeFactory } from './hubtype-node-factory'

export class HubtypeWriter implements CMSWriter {
  private nodeFactory
  constructor() {
    this.nodeFactory = new NodeFactory()
  }

  async writeContent(state: State): Promise<CMSWriteResponse> {
    const flows = HtFlowFactory.getFlows(state)
    const startNodeId = this.getStartNodeId(flows)

    const { hubtypeNodes, webviewContent } =
      this.nodeFactory.getHubtypeNodes(state)

    const flowData: HubtypeFlowBuilderData = {
      version: DRAFT_VERSION,
      name: 'Test data',
      locales: this.getLocales(state),
      hash: '',
      nodes: hubtypeNodes,
      is_knowledge_base_active: state.isKnowledgeBaseActive,
      bot_variables: state.botVariables,
      start_node_id: startNodeId,
      flows,
      webviews: state.flows
        .filter(flow => isWebview(flow.id))
        .map(flow => ({ name: flow.name, id: flow.id.split('_')[1] })),
      webview_contents: webviewContent,
    }
    const newHash = await getHash(structuredClone(flowData))
    flowData.hash = newHash
    const feedbackStatus = await FlowBuilderService.saveFlow(
      state.authToken,
      flowData,
      state.organizationContents.currentBot.id
    )
    return { feedbackStatus, newHash }
  }

  async uploadFile(
    authToken: string,
    file: File,
    botId: string
  ): Promise<AssetFields | null> {
    const url = await FlowBuilderService.uploadFile(authToken, file, botId)
    if (!url) return null
    return new AssetFields(url)
  }

  private getLocales(state: State) {
    const currentLocaleCode = state.currentLocale.code
    return [
      currentLocaleCode,
      ...state.locales
        .filter(locale => locale.code !== currentLocaleCode)
        .map(locale => locale.code),
    ]
  }

  private getStartNodeId = (flows: HubtypeFlow[]): string | undefined => {
    return flows.find(flow => flow.id === MAIN_FLOW.id)?.start_node_id
  }
}
